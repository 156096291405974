import { v4 as uuid4 } from 'uuid';

/**
 * Generate a new Genome Identifier of format:
 *
 * http://data.disney.com/resources/{uuid-v4}-{YEAR}{MONTH}{DAY}
 */
export function generateIdentifier(): string {
  const uuid = uuid4();
  const d = new Date(Date.now());
  const date = ('' + d.getUTCDate()).padStart(2, '0');
  const month = ('' + (d.getUTCMonth() + 1)).padStart(2, '0');
  const year = ('' + d.getUTCFullYear()).padStart(2, '0');

  return `http://data.disney.com/resources/${uuid}-${year}${month}${date}`;
}
